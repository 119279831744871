<template>
    <v-stepper v-model="step">
        <v-stepper-header>
            <v-stepper-step id="v-support-2" name="#step1" class="pointer" :complete="step > 1" step="1"
                @click="loadStep(1)">I Need Help With...</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step id="v-support-3" name="#step2" :complete="step > 2" step="2">Common
                Solutions</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step id="v-support-4" name="#step3" class="pointer" step="3" @click="loadStep(3)">Create Support
                Ticket</v-stepper-step>
        </v-stepper-header>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-card full-width flat class="d-flex flex-column justify-center text-center mt-10">
            <p class="text-sm-h4 text-md-h3 text-lg-h2">Search Our Knowledge Base</p>
            <p class="text-sm-h6 text-md-h5 text-lg-h4">for answer, documents, links and more</p>
            <q-search-support label="search"></q-search-support>
        </v-card>
        <v-stepper-items>
            <v-stepper-content step="1" class="pa-3">
                <v-row>
                    <v-col cols="12" sm="6" md="4" v-for="(category, i) in categories" :key="i">
                        <v-btn block x-large dark min-width="400" :color="buttonColors[category.category]"
                            @click="loadSolutions(category)">{{ category.category }}</v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-btn block x-large dark min-width="400" :color="buttonColors['HQ Feature Request']"
                            @click="showHqFeatureRequest()">HQ Feature Request</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0">
                <v-row cols="12" class="my-5 mx-4 d-flex justify-space-between">
                    <v-btn outlined color="primary" @click="loadStep(1)">
                        <v-icon class="pr-3">fas fa-chevron-left</v-icon>back
                    </v-btn>
                </v-row>
                <common-solutions :category="current_category"></common-solutions>
                <!--
                <v-row cols="12" class="my-5 px-4 d-flex justify-space-between">
                    <v-spacer></v-spacer>
                    <p class="pt-2 mr-7">Didn’t find the answer you are looking for?</p>
                    <v-btn class="pl-5" dark color="primary" @click="loadStep(3)">
                        Submit A Ticket
                    </v-btn>
                </v-row>
            -->
            </v-stepper-content>
            <v-stepper-content step="3" class="pa-0">
                <v-row justify="center" class="ma-4">
                    <v-col cols="12" sm="7">
                        <h2>Create a Support Ticket</h2>
                        <v-btn v-if="hasRole(['Staff', 'SuperAdmin', 'Exec'])"
                            href="https://form.jotform.com/212626256941154" color="primary" class="py-4"
                            target="_blank">Create Internal Ticket</v-btn>
                        <form v-else>
                            {{ help_type }}
                            <v-select label="Department" return-object :items="departments" item-text="category"
                                item-value="category" v-model="current_category"></v-select>
                            <v-text-field :counter="10" label="Name" required v-model="name"></v-text-field>
                            <v-text-field label="E-mail" disabled required v-model="email"></v-text-field>
                            <v-text-field label="Subject" required v-model="subject"></v-text-field>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea label="How can we help you today?" v-model="comment"></v-textarea>
                                    <!--XX-->
                                    <div>
                                        <span v-for="(attachment, key) in attachments" :key="key">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span :href="attachment.content_url" target="_blank" v-bind="attrs"
                                                        v-on="on" :key="key"
                                                        class="elevation-0 attachment-thumbnail-wrapper pa-3 ma-3">
                                                        <v-icon color="primary" style="font-size: 40px !important">fas
                                                            fa-file-alt fa-3x</v-icon>
                                                    </span>
                                                </template>
                                                <span>{{ attachment.file_name }}</span>
                                            </v-tooltip>
                                        </span>
                                    </div>
                                    <v-col style="position: relative; max-width: 300px">
                                        <q-file-upload ref="dropzone" text="Attach a file"
                                            :upload-url="'/api/my/tickets/0/attachment'" :params="{}"
                                            accepted-files="application/vnd.ms-excel, application/csv, application/vnd.ms-word,.csv,.txt,.jpg,.png,.gif,.docx,.doc,.xlsx,.pdf,.msg"
                                            upload-name="document" :max-files="null" v-on:complete="uploadComplete"
                                            v-on:fileadded="uploading = true"></q-file-upload>
                                        <div style="position: absolute; top: 0px; right: 5px">
                                            <v-tooltip right>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon color="warning" dark v-bind="attrs" v-on="on"> fas
                                                        fa-question-circle </v-icon>
                                                </template>
                                                <span>Click or drag attachments into the drop zone.<br />You must
                                                    include a comment to SUBMIT</span>
                                            </v-tooltip>
                                        </div>
                                    </v-col>
                                    <v-row cols="12" class="my-5 d-flex justify-space-between">
                                        <v-btn :disabled="uploading || comment === null" class="py-4" color="primary"
                                            @click="createTicket">submit</v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" outlined @click="loadStep(2)" class="py-4">Back</v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </form>
                    </v-col>
                </v-row>
            </v-stepper-content>
        </v-stepper-items>
        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{ dialog_text }}
                    <v-progress-circular v-if="ticket_loading" indeterminate color="primary"></v-progress-circular>
                    <div v-if="dialog_text == 'Problems!'">
                        <v-btn @click="dialog = false">Close</v-btn>
                    </div>
                </v-card-title>
            </v-card>
        </v-dialog>
    </v-stepper>
</template>
<script>
import QSearch from "@/components/utils/QSearch";
import CommonSolutions from "./CommonSolutions.vue";
import Page from "@/store/Models/Page";
import QuilityAPI from "@/store/API/QuilityAPI.js";
import Category from "@/store/Models/Category";
import QFileUpload from "@/components/utils/QFileUpload.vue";
import QSearchSupport from "@/components/utils/QSearchSupport";

export default {
    data() {
        return {
            dialog: false,
            dialog_text: null,
            ticket_loading: false,
            step: null,
            help_type: null,
            current_category: {},
            name: null,
            email: null,
            comment: null,
            subject: null,
            attachments: [],
            uploading: false,
            buttonColors: {
                Leads: "q_leads_1",
                "New Business": "q_new_b_1",
                Contracting: "q_new_b_1",
                Marketing: "q_resources_1",
                "Agency Building": "q_agency_1",
                Other: "primary",
                "Quility Dashboard": "primary",
                "Quility Tech": "q_quility_tech",
                "Quility U": "q_blue_1",
                Events: "q_resources_3",
                "Bonuses & Payments": "q_blue_2",
                "Contests & Leaderboards": "q_blue_2",
                Summit: "q_quility_tech",
                "HQ Feature Request": "q_new_b_1",
                QuilityRX: "q_resources_1",
                Switchboard: "q_quility_tech",
            },
        };
    },
    mounted: function () {
        this.name = this.$auth.auth_user["http://quility.com/user_data"].name;
        this.email = this.$auth.auth_user["http://quility.com/user_data"].email;
        if (this.$route.params.department) {
            this.current_category = this.getCategoryByName(this.$route.params.department);
        }
        if (this.$route.hash != "") {
            var g = this;
            this.$nextTick(function () {
                try {
                    g.loadStep(g.$route.hash.replace("#step", ""));
                } catch (error) {
                    //console.log("Hash not found")
                }
            });
        }
    },
    methods: {
        loadSolutions: function (category) {
            this.current_category = category;
            QuilityAPI.getSupportCategoryPages(category.id);
            this.loadStep(2);
            this.trackSupport();
            this.logActivity(" SupportSection", { category: this.current_category });
        },
        createTicket: function () {
            var g = this;
            this.dialog = true;
            this.dialog_text = "Submitting Ticket...";
            this.ticket_loading = true;
            var ticket = {
                type: this.current_category,
                tags: ["quility", "dashboard", "Division_" + this.divisions[0]],
                subject: this.subject,
                name: this.name,
                comment: this.comment,
                priority: "normal",
            };
            QuilityAPI.newTicket(ticket, this.attachments).then(function (t) {
                g.ticket_loading = false;
                if (t.error) {
                    g.dialog_text = "Problems! " + t.message;
                } else {
                    g.trackSupportTicket("New Ticket Created");
                    g.dialog_text = "Complete!";
                    QuilityAPI.getTickets();
                    setTimeout(g.$router.push("/support/tickets/" + t.ticket.id), 2000);
                    g.current_category = {};
                    //g.name = null
                    //g.email = null
                    g.comment = null;
                    g.subject = null;
                    g.attachments = [];
                }
            });
        },
        loadStep(step) {
            this.$router.push({ hash: "" });
            this.step = step;
            if (this.step == 3) {
                this.logActivity("SupportTicketForm", { category: this.current_category });
                this.trackSupportTicket("Ticket Form Loaded");
            }
        },
        trackSupport: function () {
            gtag("event", "Support", {
                event_category: "Common Solutions",
                event_label: this.current_category,
            });
        },
        trackSupportTicket: function (what) {
            gtag("event", "Support", {
                event_category: "Ticket",
                event_label: what,
            });
        },
        startDemo: function () {
            this.$tours["DemoSupport"].start();
        },
        uploadComplete: function (data) {
            this.uploading = false;
            this.attachments.push(data.data);
            this.$refs.dropzone.removeFile(data.file);
        },
        getCategoryByName: function (n) {
            n = n.replace("_", " ");
            for (var i = 0; i < this.departments.length; i++) {
                if (this.departments[i].category == n) {
                    return this.departments[i];
                }
            }
            return null;
        },
        showHqFeatureRequest: function () {
            this.current_category = "Feature Request";
            this.loadStep(3);
        },
    },
    computed: {
        loading: function () {
            return this.$store.state.entities.page.loading;
        },
        categories: function () {
            return Category.query()
                .where("zendesk_category_id", (value) => value != 0 && value != null)
                .get();
        },
        departments: function () {
            var cats = [...this.categories];
            cats.push({
                category: "Quility U",
                dropdown_category_name: "Quility U",
            });
            cats.push({
                category: "Quility Tech",
                dropdown_category_name: "Quility Tech",
            });
            cats.push({
                category: "Quility Events",
                dropdown_category_name: "Quility Events",
            });
            cats.push({
                category: "Business Intelligence",
                dropdown_category_name: "Business Intelligence",
            });
            cats.push({
                category: "Quility HQ Help",
                dropdown_category_name: "Quility HQ Help",
            });
            cats.push({
                category: "Bug",
                dropdown_category_name: "Bug",
            });
            cats.push({
                category: "Feature Request",
                dropdown_category_name: "Feature Request",
            });
            return cats.sort(function (a, b) {
                if (a.category.toUpperCase() < b.category.toUpperCase()) {
                    return -1;
                }
                if (a.category.toUpperCase() > b.category.toUpperCase()) {
                    return 1;
                }
                return 0;
            });
        },
    },
    watch: {
        step: function (newV) {
            //this.$router.push({ hash: '#step' + newV })
        },
        "$route.hash": function (hash) {
            if (hash == "") {
                return;
            }
            var g = this;
            this.$nextTick(function () {
                try {
                    g.loadStep(hash.replace("#step", ""));
                } catch (error) {
                    //console.log("Hash not found")
                }
            });
        },
    },
    components: {
        CommonSolutions,
        QFileUpload,
        QSearch,
        QSearchSupport,
    },
};
</script>
<style>

</style>
<style scoped>
.v-tour__target--relative {
    margin-top: 0px !important;
}
</style>