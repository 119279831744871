<template>
    <v-container fluid class="grey lighten-5 pa-0 ma-0">
        <q-support-submenu has-demo v-on:demo="startDemo()"></q-support-submenu>
        <v-row cols="12" class="mx-4 d-flex align-center">
            <v-btn outlined color="primary" :to="{ name: 'Support' }">
                <v-icon class="pr-3">fas fa-chevron-left</v-icon>back
            </v-btn>
			<h4 class="ma-0 ml-4">{{ categoryObj.category }}</h4>
        </v-row>
        <common-solutions :category="categoryToShow">
			<template v-slot:prepend>
				<SubCategoriesList class="sub-categories-list" v-model="selectedSubCategory" :list="childCategories" />
			</template>
		</common-solutions>
    </v-container>
</template>
<script>
import QSupportSubmenu from '@/components/navigation/Submenus/QSupportSubmenu.vue';
import CommonSolutions from './CommonSolutions.vue'
import SubCategoriesList from './components/SubCategoriesList.vue';
import Category from '@/store/Models/Category'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    components: { CommonSolutions, QSupportSubmenu, SubCategoriesList },

    props: {
        category: { type: String }
    },

	data() {
		return {
			selectedSubCategory: null,
		}
	},

    computed: {
        categoryObj() {
            const category = Category.query().where('category', (value) => value === this.category).get();

            if (!category || category.length == 0) {
                return this.$router.push('/404')
            }

            return category[0];
        },
		childCategories() {
			return Category
                .query()
                .where('parent_category_id', this.categoryObj.id)
                .get()
                .sort((a, b) => a.order - b.order);
		},
		categoryToShow() {
			return this.selectedSubCategory || this.categoryObj;
		}
    },

    watch: {
        categoryToShow: {
            handler(newVal) { QuilityAPI.getSupportCategoryPages(newVal.id) },
            immediate: true
        }
    }
}
</script>

<style lang="scss">
  .sub-categories-list {
	min-width: 25%;
  }
</style>