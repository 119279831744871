<template>
    <div>
        <h2>{{ formTitle }}</h2>
        <form>
            <v-select label="Department" return-object :items="departments" item-text="category" item-value="category" v-model="currentDepartment" />
			<template v-if="isSubmitAvailable">
				<v-text-field label="Name" disabled required v-model="name" />
				<v-text-field label="E-mail" disabled required v-model="email" />
				<q-agent-lookup
					v-if="isRestrictedCategory && isAgencyOwner"
					v-model="onBehalfOfAgent"
					:filters="{ Status : 'Active,Available,Lapsed,Stalled,Terminated' }"
					return-object
					label="Submitting on behalf of agent"
					placeholder="Start typing to Search for an agent"
                    @input="showConfirmationDialog"
				/>
			</template>
            <v-select v-if="isMRPCategory" :label="subjectLabel" :loading="categoryPageLoading" :items="ticketSubjects" v-model="subject" />
            <v-text-field v-else label="Subject" v-model="subject" />
			<v-alert v-if="ticketDescription" border="top" colored-border type="info" elevation="2">
				<div class="ticket-description" v-html="ticketDescription" />
			</v-alert>
            <v-row v-if="additionalFieldsAvailable">
                <v-col cols="12">
                    <v-textarea :label="commentLabel" v-model="comment"></v-textarea>
                    <div>
                        <span v-for="(attachment, key) in attachments" :key="key">
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <span :href="attachment.content_url" target="_blank" v-bind="attrs" v-on="on" :key="key" class="elevation-0 attachment-thumbnail-wrapper pa-3 ma-3">
                                        <v-icon color="primary" style="font-size: 40px !important">fas fa-file-alt fa-3x</v-icon>
                                    </span>
                                </template>
                                <span>{{ attachment.file_name }}</span>
                            </v-tooltip>
                        </span>
                    </div>
                    <v-col style="position: relative; max-width: 300px">
                        <q-file-upload ref="dropzone" text="Attach a file" :upload-url="'/api/my/tickets/0/attachment'" :params="{}" accepted-files="application/vnd.ms-excel, application/csv, application/vnd.ms-word,.csv,.txt,.jpg,.png,.gif,.docx,.doc,.xlsx,.pdf,.msg" upload-name="document" :max-files="null" v-on:complete="uploadComplete" v-on:fileadded="uploading = true"></q-file-upload>
                        <div style="position: absolute; top: 0px; right: 5px">
                            <v-tooltip right>
                                <template #activator="{ on, attrs }">
                                    <v-icon color="warning" dark v-bind="attrs" v-on="on"> fas fa-question-circle </v-icon>
                                </template>
                                <span>Click or drag attachments into the drop zone.<br />You must include a comment to SUBMIT</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                </v-col>
            </v-row>
			<v-row v-else justify="center">
				<v-col v-if="subject" cols="12" md="9">
					<p class="ticket-message" v-html="salesRepMessage" />
				</v-col>
			</v-row>
			<v-row cols="12" no-gutters class="mt-4">
				<v-btn color="primary" outlined @click="goToSupportPage">Back</v-btn>
				<v-spacer />
				<v-btn v-if="isSubmitAvailable" :disabled="isSubmitDisabled" color="primary" @click="createTicket">
					submit
					<v-progress-circular v-if="ticketLoading" class="ml-2" indeterminate size="24" />
				</v-btn>
			</v-row>
        </form>

        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2" :class="!ticketLoading && !ticketError && 'card-title--success'">
                    {{ dialogText }}
                    <v-progress-circular v-if="ticketLoading" class="ml-2" indeterminate color="primary" size="24" />
                </v-card-title>
				<v-card-actions v-if="ticketError">
					<v-btn class="ml-auto" @click="closeDialog">Close</v-btn>
				</v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="isConfirmationDialogVisible" width="500" class="p-10">
            <v-card>
                <v-card-title>
                    <p>Using the “Submitting on behalf of agent” feature will copy the agent selected on all communications.</p>
                    <p>Are you sure you want to proceed?</p>
                </v-card-title>
				<v-card-actions class="d-flex justify-space-around pb-6">
					<v-btn color="primary" @click="isConfirmationDialogVisible = false">Confirm</v-btn>
					<v-btn @click="cancelAgentSelection()">Cancel</v-btn>
				</v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Category from '@/store/Models/Category'
import Page from '@/store/Models/Page'
import QFileUpload from '@/components/utils/QFileUpload.vue'
import QAgentLookup from '@/components/utils/QAgentLookup.vue'

const commonCategories = [
	{ category: 'Quility U', dropdown_category_name: 'Quility U' },
	{ category: 'Quility Tech', dropdown_category_name: 'Quility Tech' },
	{ category: 'Events', dropdown_category_name: 'Events' },
	{ category: 'Business Intelligence', dropdown_category_name: 'Business Intelligence' },
	{ category: 'Quility HQ Help', dropdown_category_name: 'Quility HQ Help' },
	{ category: 'Bug', dropdown_category_name: 'Bug' },
	{ category: 'Feature Request', dropdown_category_name: 'Feature Request' },
]

const macrosResponsesPages = [
	{ category: 'Bonuses & Payments', slug: 'bonus-payments-macro-responses' },
	{ category: 'Business Intelligence', slug: 'business-intelligence-macro-responses', restricted: true },
	{ category: 'Contests & Leaderboards', slug: 'contests-leaderboards-macro-responses' },
	{ category: 'Contracting', slug: 'contracting-macro-responses', restricted: true },
	{ category: 'Leads', slug: 'leads-macro-responses' },
	{ category: 'Marketing', slug: 'marketing-macro-responses' },
	{ category: 'Quility Events', slug: 'quility-events-macro-responses' },
]

export default {
	components: {
        QFileUpload,
		QAgentLookup,
    },
    props: {
		category: String,
	},
    data () {
        return {
            dialog: false,
            dialogText: null,
            currentDepartment: null,
            name: null,
            email: null,
            comment: null,
            subject: null,
            attachments: [],
            uploading: false,
            ticketLoading: false,
            ticketError: false,
			categoryPage: null,
			categoryPageLoading: false,
			onBehalfOfAgent: undefined,
            isConfirmationDialogVisible: false,
        }
    },
	created () {
		this.getCurrentDepartment(this.category)
		if (this.MRPCategorySlug) {
			this.loadCategoryPage(this.MRPCategorySlug)
		}
	},
    mounted () {
        this.name = this.$auth.auth_user['http://quility.com/user_data'].name
        this.email = this.$auth.auth_user['http://quility.com/user_data'].email
    },
    computed: {
        categories () {
            return Category.query().where('zendesk_category_id', Boolean).get()
        },
		isMRPCategory () {
			if (!this.currentDepartment) { return false }
			return macrosResponsesPages.some(({ category }) => this.currentDepartment.category === category)
		},
		MRPCategorySlug () {
			if (!this.isMRPCategory) { return }
			return macrosResponsesPages.find(({ category }) => this.currentDepartment.category === category).slug
		},
		isRestrictedCategory () {
			if (!this.isMRPCategory) { return false }
			return macrosResponsesPages.find(({ category }) => this.currentDepartment.category === category).restricted
		},
        departments () {
            const categories = [...this.categories, ...commonCategories]
            return categories.sort(function(a, b) {
                if (a.category.toUpperCase() < b.category.toUpperCase()) return -1
				if (a.category.toUpperCase() > b.category.toUpperCase()) return 1
                return 0
            })
        },
		ticketSubjects () {
			if (!this.categoryPage) return []
			return this.categoryPage.blocks.map(({ Title }) => Title)
		},
		ticketDescription () {
			if (!this.categoryPage) return
			return this.categoryPage.blocks.find(({ Title }) => Title === this.subject)?.text
		},
		isAgencyOwner () {
			return this.hasRole(['AgencyOwner'])
		},
		commentLabel () {
			return this.isMRPCategory && this.subject
				? 'Still need help? Open a support ticket.'
				: 'How can we help you today?'
		},
		additionalFieldsAvailable () {
			if (this.isRestrictedCategory) {
				return this.isAgencyOwner && this.subject
			}
			if (this.isMRPCategory) {
				return !!this.subject
			}
			return  true
		},
		isSubmitAvailable () {
			if (this.isRestrictedCategory) {
				return this.isAgencyOwner
			}
			return true
		},
		isSubmitDisabled () {
			return this.uploading || !this.comment
		},
		salesRepMessage () {
			const agentEmail = this.user?.Agent.BusinessEmail
			const email = agentEmail && `<a href="mailto:${agentEmail}" target="_blank">${agentEmail}</a>`
			return email
				? `If you still need assistance, please contact your AO at ${email} to submit a support ticket on your behalf.`
				: 'If you still need assistance, please contact your AO to submit a support ticket on your behalf.'
		},
		formTitle () {
			if (this.isRestrictedCategory && !this.isAgencyOwner) {
				return `Select a ${this.currentDepartment?.category} Subject`
			}
			return 'Create a Support Ticket'
		},
		subjectLabel () {
			return this.onBehalfOfAgent?.AgentName
				? `Ticket Subject (on behalf of ${this.onBehalfOfAgent.AgentName})`
				: 'Ticket Subject'
		},
    },
    watch: {
        category (val) {
            this.getCurrentDepartment(val)
        },
		MRPCategorySlug: async function (newSlug, oldSlug) {
			if (oldSlug) {
				this.subject = null
				this.categoryPage = null
				this.onBehalfOfAgent = undefined
			}
			if (newSlug) {
				this.loadCategoryPage(newSlug)
			}
		},
    },
    methods: {
		getCurrentDepartment (categoryName) {
			this.currentDepartment = this.departments.find((item) => item.category === categoryName)
		},
		async loadCategoryPage (slug) {
			// check store
			let page = Page.query().where('slug', slug).first()
			if (page) {
				this.categoryPage = page
				return Promise.resolve()
			}
			// load from API
			this.categoryPageLoading = true
			await QuilityAPI.getPageWithSlug(slug)
			page = Page.query().where('slug', slug).first()
			if (page) {
				this.categoryPage = page
			} else {
				this.showError('Oops! There was a problem loading category page.')
			}
			this.categoryPageLoading = false
		},
        async createTicket () {
            this.dialog = true
            this.ticketLoading = true
            this.dialogText = 'Submitting Ticket...'
			const subject = this.onBehalfOfAgent?.AgentName
				? `${this.subject} (on behalf of ${this.onBehalfOfAgent.AgentName})`
				: this.subject
            const ticket = {
                type: this.currentDepartment,
                tags: ['quility', 'dashboard', `Division_${this.divisions[0]}`],
                subject,
                name: this.name,
                comment: this.comment,
                priority: 'normal',
				onBehalfOfAgent: this.onBehalfOfAgent?.AgentCode || null,
            }
			try {
				const data = await QuilityAPI.newTicket(ticket, this.attachments)
				this.trackSupportTicket('New Ticket Created')
                this.ticketLoading = false
				this.dialogText = 'Complete!'
				this.clearTicketData()
				await QuilityAPI.getTickets()
				this.$router.push(`/support/tickets/${data.ticket.id}`)
			} catch (e) {
				this.ticketLoading = false
				this.ticketError = true
				this.dialogText = `Problems! ${e.msg}`
			}
        },
        trackSupportTicket (what) {
            gtag('event', 'Support', {
                event_category: 'Ticket',
                event_label: what,
            })
        },
        uploadComplete (data) {
            this.uploading = false
			this.attachments = [...this.attachments, data.data]
            this.$refs.dropzone.removeFile(data.file)
        },
		goToSupportPage () {
			this.clearTicketData()
			this.$router.push('/support')
		},
		closeDialog () {
			this.ticketError = false
			this.dialog = false
		},
		clearTicketData () {
			this.currentDepartment = null
			this.comment = null
			this.subject = null
			this.categoryPage = null
			this.attachments = []
			this.onBehalfOfAgent = undefined
		},
        showConfirmationDialog(value) {
            if (value) {
                this.isConfirmationDialogVisible = true
            }
        },
        cancelAgentSelection() {
            this.onBehalfOfAgent = undefined
            this.isConfirmationDialogVisible = false
        },
    },
}
</script>

<style scoped>
.v-tour__target--relative {
    margin-top: 0px !important;
}
</style>

<style lang="scss">
.ticket-description {
	p:last-child {
		margin-bottom: 0;
	}
}

.ticket-message {
	margin-top: 1rem;
	font-size: 1.25em;
	line-height: 1.5;
    color: #444;
    font-weight: 700;
	text-align: center;
}

.v-dialog {
	.v-card {
		.card-title--success {
			background-color: #4caf50 !important;
			color: white;
		}
	}
}
</style>
